<template>
    <router-view/>
</template>

<style src="./assets/Stylesheets/Shared/main.css"></style>

<script>
export default {
    data() {
        return {
            theme: this.$store.state.themeModule.theme
        };
    },
    mounted() {

        this.$store.commit('themeModule/FETCH_THEME')
        
    },
}
</script>
