import { createStore } from 'vuex'
import themeModule from './modules/theme.module.js'
import localeModule from './modules/locale.module.js'

export const store = createStore({
  modules: {
    themeModule: themeModule,
    localeModule: localeModule
  }

})