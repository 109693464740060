import { createRouter , createWebHistory } from 'vue-router'


const lazyLoad = (view) => { return () => import(`../views/${view}.vue`) }


const routes = [
  {
    path: '/',
    name: 'home',
    component: lazyLoad('Auth/Login') // TODO: Change this to Dashboard view when ready (with middleware that redirects to login if user is not logged in)
  },
  {
    path: '/login',
    name: 'login',
    component: lazyLoad('Auth/Login')
  },
  {
    path: '/sign-up',
    name: 'sign up',
    component: lazyLoad('Auth/Register')
  },
  {
    path: '/new-password',
    name: 'new password',
    component: lazyLoad('Auth/NewPassword')  //TODO: Add parameters to URL to authenticate user that can change pw
  },
  {
    path: '/recover-password',
    name: 'recover password',
    component: lazyLoad('Auth/RecoverPassword')
  },
  {
    path: '/thank-you',
    name: 'thank you',
    component: lazyLoad('Auth/ThankYou')
  },
  {
    path: '/email-validation/:email/:token',
    name: 'email validation',
    component: lazyLoad('Auth/EmailValidation')
  },
  {
    path: '/register-data/:userId',
    name: 'register-data',
    component: lazyLoad('Auth/RegisterData')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
