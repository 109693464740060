export default {
    namespaced: true,

    
    state:() => ({
        locales: ["en", "it", "fr", "es"],
        dropdown:false
    }),
    
    mutations:{
        
            CHANGE_LOCALE(state,payload){
                payload.i18n.locale = payload.lang
                document.getElementById('locales-dropdown').style.display='none';
                localStorage.setItem('lang',payload.lang);
                state.dropdown=false;
            },
            OPEN_DROPDOWN(state){
                document.getElementById('locales-dropdown').style.display='block';
                state.dropdown=true;
            },
            CLOSE_DROPDOWN(state){
                document.getElementById('locales-dropdown').style.display='none';
                state.dropdown=false;
            }
    
      },

    actions:{
        changeLanguage (context, payload) {
            context.commit('CHANGE_LOCALE' , payload)
          }
    }
  }